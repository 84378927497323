<template>
  <div class="body-right-info edp-right-detail" style="overflow-y: auto; --detailWidth: 470px;--miniDetailWidth: 420px;"
       v-if="detail">
    <a-tabs v-model="activeTabData" class="container-tabs" rowKey="prooid" default-active-key="1"
            style="overflow-y: unset">
      <a-tab-pane key="1" tab="基本信息">
        <div class="info-scroll-content">
          <div class="content-head">
            <div class="content-head-id">
              <img :src="`${$appsettings.imgPrefix}product_inventory_blue.png`" alt="">
              <span class="id">#{{ detail.seqid }}</span>
            </div>
          </div>
          <div class="title">
            <edp-input
                @change="$emit('reload')"
                v-model="detail.product_name"
                :db="dbs.proCore"
                field-name="product_name"
                :pro-oid="detail.oid"
                :oid="detail.oid"
                size="big"
            >
            </edp-input>
          </div>
          <div class="content-group-info">
            <!--              <div class="group-info-title">属性</div>-->
            <div class="group-info-list">
              <div class="info-item">
                <div class="info-key">产品编号</div>
                <div class="info-value">
                  <edp-input
                      @change="$emit('reload')"
                      v-model="detail.product_code"
                      :db="dbs.proCore"
                      field-name="product_code"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      size="middle"
                  >
                  </edp-input>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">产品版本</div>
                <div class="info-value">
                  <edp-input
                      @change="$emit('reload')"
                      v-model="detail.product_series"
                      :db="dbs.proCore"
                      field-name="product_series"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      size="middle"
                  >
                  </edp-input>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">产品类型</div>
                <div class="info-value">
                  <edp-select
                      @change="$emit('reload')"
                      showSearch
                      v-model="detail.product_type"
                      :db="dbs.proCore"
                      field-name="product_type"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      cat-type="product_type"
                  >
                  </edp-select>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">单位</div>
                <div class="info-value">
                  <edp-input
                      @change="$emit('reload')"
                      v-model="detail.unit"
                      :db="dbs.proCore"
                      field-name="unit"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      size="middle"
                  >
                  </edp-input>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">品牌</div>
                <div class="info-value">
                  <edp-input
                      @change="$emit('reload')"
                      v-model="detail.band"
                      :db="dbs.proCore"
                      field-name="band"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      size="middle"
                  >
                  </edp-input>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">供应商</div>
                <div class="info-value">
                  <edp-select
                      showSearch
                      ref="companyName"
                      v-model="detail.company_oid"
                      :db="dbs.proCore"
                      field-name="company_oid"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      :options="companyOptions"
                      @change="() => {
                      getDetail()
                      $emit('reload')
                    }"
                  ></edp-select>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">供应商编号</div>
                <div class="info-value">
                  <auto-input
                      popperClass="edp-input"
                      url="/company/retrieve"
                      value-key="company_no"
                      label-key="company_no"
                      v-model="detail.company_no"
                      :label.sync="detail.company_no"
                      @change="companyNoChange"
                  />
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">产品类别</div>
                <div class="info-value">
                  <edp-select
                      @change="$emit('reload')"
                      showSearch
                      v-model="detail.product_category"
                      :db="dbs.proCore"
                      field-name="product_category"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      cat-type="product_category"
                  >
                  </edp-select>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">规格型号</div>
                <div class="info-value">
                  <edp-input
                      @change="$emit('reload')"
                      v-model="detail.tech_info"
                      :db="dbs.proCore"
                      field-name="tech_info"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      size="middle"
                  >
                  </edp-input>
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">原厂家</div>
                <div class="info-value">
                  <edp-select
                      @change="$emit('reload')"
                      showSearch
                      v-model="detail.product_company"
                      :db="dbs.proCore"
                      field-name="product_company"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      :options="companyOptions"
                  ></edp-select>
                </div>
              </div>
              <!--              <div class="info-item" v-if="prjPro">-->
              <!--                <div class="info-key">平台产品负责人</div>-->
              <!--                <div class="info-value user-group">-->
              <!--                  <img :src="detail.product_admin_pic" alt="">-->
              <!--                  <edp-select-->
              <!--                    showSearch-->
              <!--                    @change="getDetail"-->
              <!--                    :db="dbs.proCore"-->
              <!--                    :pro-oid="detail.oid"-->
              <!--                    :oid="detail.oid"-->
              <!--                    account="all"-->
              <!--                    field-name="product_admin"-->
              <!--                    v-model="detail.product_admin"-->
              <!--                  ></edp-select>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="info-item">
                <div class="info-key">创建时间</div>
                <div class="info-value">
                  {{ detail.create_time }}
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">创建人</div>
                <div class="info-value user-group">
                  <img :src="detail.creator_oid_pic">
                  {{ detail.creator_oid_title ? detail.creator_oid_name + '-' + detail.creator_oid_title : detail.creator_oid_name }}
                </div>
              </div>
              <div class="info-item">
                <div class="info-key">产品说明</div>
                <div class="info-value">
                  <edp-input
                      @change="$emit('reload')"
                      v-model="detail.product_intro"
                      :db="dbs.proCore"
                      field-name="product_intro"
                      :pro-oid="detail.oid"
                      :oid="detail.oid"
                      size="middle"
                  ></edp-input>
                </div>
              </div>
            </div>
            <attachment-upload style="margin-top: 25px;" :source-oid="sourceOid ?? prooid"
                               :prj-oid="$route.query.prj_oid" sourceType="2">
              <template #title><span style="font-size: 16px;">附件列表</span></template>
            </attachment-upload>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="BOM" v-if="$slots.bom">
        <div class="tabs-content" style="padding-left:10px">
          <slot name="bom"></slot>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="动态" v-if="prjPro">
        <slot name="bizlog"></slot>
      </a-tab-pane>
      <div slot="tabBarExtraContent" style="font-size: 14px;padding-right: 20px;">
        <div class="action-item" @click="delPro" style="cursor: pointer">删除</div>
      </div>
    </a-tabs>
  </div>
</template>

<script>
import AttachmentUpload from "@/components/attachmentUpload.vue";
import EdpSelect from "@/components/e-form/edp-select.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import { fetch } from "@/utils/request";
import { FieldUpdateDb } from "@/utils/FieldUpdate";
import autoInput from "@/components/autoInput.vue";

export default {
  name: "productDetail",
  emits: ['reload', 'delete'],
  props: {
    sourceOid: {
      type: String,
    },
    defaultActive: {
      type: String,
    },
    activeTab: {
      type: String,
    },
    prooid: {
      type: String
    },
    prjPro: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTabData: "1",
      dbs: {
        proCore: new FieldUpdateDb("pro", "pro_core"),
      },
      detail: false,
      companyOptions: []
    }
  },
  watch: {
    activeTabData: {
      handler(val) {
        if (val !== this.activeTab) {
          this.$emit('update:activeTab', val)
        }
      }
    },
    activeTab: {
      immediate: true,
      handler(val) {
        if (val) {
          this.activeTabData = val
        }
      }
    },
    prooid: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.detail = false
        } else {
          if (this.defaultActive) {
            this.activeTabData = this.defaultActive
          }
          this.getDetail()
        }
      }
    }
  },
  created() {
    this.getCompanyList()
    if (this.activeTab && this.defaultActive) {
      console.error("不要同时传activeTab和defaultActive参数")
    }
    if (!this.activeTab && this.defaultActive) {
      this.activeTabData = this.defaultActive
    }
  },
  methods: {
    getCompanyList() {
      fetch('post', '/company/retrieve', { keywords: '', page_size: 999 }).then((res) => {
        if (res.status === 0) {
          this.companyOptions = res.data.datas.map(item => {
            return {
              key: item.oid,
              label: item.company_name,
              value: item.oid
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getDetail() {
      fetch("post", "pro/info", { oid: this.prooid }).then(res => {
        if (res.status === 0) {
          this.detail = res.data
        }
      })
    },
    delPro() {
      this.$confirm({
        title: `确定删除?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.$emit('delete')
        },
        onCancel() {
        }
      });
    },
    companyNoChange(value, item) {
      // console.log(value, item);
      this.$refs.companyName.setValue(item.oid)
    }
  },
  components: {
    AttachmentUpload,
    EdpInput,
    EdpSelect,
    autoInput
  },
}
</script>

<style lang="less" scoped>
// 右侧信息
.body-right-info {
  flex-shrink: 0;
  margin-left: auto;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ededed;
  background-color: #FFF;

  // 可以滚动的部分
  .info-scroll-content {
    padding-right: 20px;
    overflow-y: auto;
    padding-bottom: 10px;
    padding-left: 20px;
    // 头部
    .content-head {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .anticon {
        margin-right: 5px;
        font-size: 16px;
      }

      .content-head-id {
        display: flex;
        align-items: center;

        .anticon {
          color: #36A3FF;
        }

        > img {
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 8px;
        }

        .id {
          color: #4D4D4D;
          font-size: 12px;
        }
      }

      .content-head-icons {
        margin-left: auto;
      }
    }

    // 标题
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: #4D4D4D;
    }

    // 两列的状态
    .scroll-content-status {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 24px;
      margin-top: 13px;
      margin-bottom: 35px;

      .status-item {
        display: flex;
        align-items: center;

        .round {
          //background-color: #D4D4D4;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .status-item-right {
          line-height: 1;
          margin-left: 11px;

          .status-title {
            font-size: 16px;
            color: #4D4D4D;
            margin-bottom: 4px;
          }

          .status-sub-text {
            color: #A7A7A7;
            font-size: 12px;
          }
        }
      }
    }

    // 描述输入框 后期会换成富文本编辑框
    .description {
      width: 100%;
      height: 150px;
      margin-top: 15px;
      //border: 1px solid #EDEDED;
    }

    // 文本信息展示
    .content-group-info {
      padding-top: 20px;
      // 每组信息的标题
      .group-info-title {
        margin-top: 34px;
        font-size: 18px;
        color: #4D4D4D;
        margin-bottom: 15px;
      }

      // 每组信息列表
      .group-info-list {
        .info-item {
          display: flex;
          color: #4D4D4D;
          align-items: center;
          font-size: 16px;

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          .info-key {
            flex-shrink: 0;
            width: 150px;
            font-weight: 500;
          }

          .info-value {
            font-size: 14px;
            flex: 1;

            &.user-group {
              display: flex;
              align-items: center;

              > img {
                border-radius: 50%;
                width: 25px;
                height: 25px;
                margin-right: 8px;
              }
            }
          }
        }

        //动态和评论
        .container-tabs {
          height: 100%;

          .ant-tabs-nav .ant-tabs-tab {
            margin: 0;
          }

          .ant-tabs-bar {
            margin: 0;
          }

          .ant-tabs-content {
            height: 100%;
          }

          .tabs-content {
            height: 100%;

            .dynamic-items {
              padding: 13px;
              align-items: baseline;

              .info {
                padding-left: 14px;
                font-size: 12px;
                color: #909090;

                .name {
                  color: #000000;
                  margin-bottom: 9px;

                  > span {
                    color: #909090;
                  }
                }

                .detail {
                  .icon {
                    transform: rotate(-90deg);
                    color: #909090;
                  }

                  > div {
                    margin-right: 7px;
                  }

                  > div:nth-last-child(1) {
                    margin: 0;
                  }

                  .finish {
                    color: #24B47E;
                    background-color: #E9F7F2;
                  }

                  .unfinish {
                    color: #EA0D0D;
                    background: #FDE6E6;
                  }
                }
              }
            }

            .detail-title {
              padding-left: 16px;
              height: 55px;
              line-height: 55px;
              box-sizing: border-box;
              border-bottom: 1px solid #CACACA;
              font-size: 14px;
              color: #000000;
            }

            .detail-items {
              padding: 13px 41px;

              .detail-item-1 {
                margin-bottom: 14px;

                .top {
                  color: rgba(0, 0, 0, 1);
                  font-size: 12px;
                  margin-bottom: 9px;

                  > div {
                    margin-right: 5px;
                  }

                  > div:nth-last-child(1) {
                    margin-right: 0;
                    color: rgba(144, 144, 144, 1);
                  }

                  .status {
                    color: #24B47E;
                    background: #E9F7F2;
                    padding: 0 5px;
                  }

                  .status.nopass {
                    color: #EA0D0D;
                    background: #FDE6E6;
                  }
                }

                .bottom {
                  font-size: 12px;
                  color: #909090;
                  font-weight: 500;
                  align-items: baseline;
                  height: 100%;
                  position: relative;

                  > div {
                    margin-left: 14px;
                  }

                  > div:nth-last-child(1) {
                    width: 205px;
                    color: var(--primary-color);
                    font-weight: 500;
                  }

                  > div:nth-of-type(1) {
                    margin: 0;
                  }
                }
              }

              .detail-item-2 {
                font-size: 12px;
                color: #000000;
                margin-bottom: 14px;

                > button {
                  margin-right: 13px;
                }

                > button:nth-last-child(1) {
                  border: 1px solid var(--primary-color);
                  color: var(--primary-color);
                }
              }
            }

            .comment-items {
              padding: 13px;
              font-weight: 500;
              font-size: 12px;
              color: #000000;

              > img {
                width: 19px;
                height: 19px;
                vertical-align: middle;
              }

              .info {
                margin: 0 8px;

                > .name {
                }

                > .time {
                  font-weight: 400;
                  font-size: 12px;
                  color: #919191;
                }
              }

              .content {
                font-weight: 400;
                color: #919191;
              }
            }
          }
        }
      }
    }
  }

  // 右侧下方固定不滚动的部分
  .right-info-footer {
    border-top: 1px solid #eee;
    flex-shrink: 0;
    height: 96px;
    // 评论部分
    .input-group {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EDEDED;

      .input {
        height: 48px;
        border: none;
        outline: none;
        flex: 1;
        padding-left: 10px;
      }

      .btn {
        cursor: pointer;
        width: 100px;
        text-align: center;

        .anticon {
          font-size: 20px;
          color: #333;
        }
      }
    }

    // 关注部分
    .follow {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;

      .text {
        color: #4D4D4D;
        font-size: 16px;
        // 关注人数
        span {
          line-height: 16px;
          height: 16px;
          display: inline-block;
          background-color: #D9D9D9;
          border-radius: 4px 4px 4px 4px;
          font-size: 14px;
          border-radius: 4px;
          padding: 0 5px;
          margin: 0 5px;
        }
      }

      // 关注按钮
      .action {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 20px;

        .anticon {
          margin-right: 5px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
