<template>
  <div class="app-main-content">
    <card title="产品" v-model="listQuery.delivery_status" body-class="overflow-hidden page-card-body" @change="search">
      <template #tools>
        <div>
          <a-button type="primary" @click="openModal">
            <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
            产品
          </a-button>
        </div>
        <div class="right">
          <div class="item">
            <div class="label">排序</div>
            <a-select v-model="listQuery.order_by" @change="search">
              <a-select-option value="">默认</a-select-option>
              <a-select-option value="z.complete_percent asc">进度正序</a-select-option>
              <a-select-option value="z.complete_percent desc">进度倒序</a-select-option>
            </a-select>
          </div>
          <div class="item" style="margin-right: 20px;">
            <a-input v-model="listQuery.keywords" placeholder="请输入标题" style="width: 140px" class="search-input" >
              <a-icon slot="prefix" type="search"/>
            </a-input>
          </div>
          <div class="item">
            <a-button class="search" @click="search">查询</a-button>
          </div>
          <div class="item">
            <input style="position: fixed;z-index: -999;left: -200%;" type="file" ref="file" @change="importData"/>
            <a-dropdown>
              <img class="more" :src="$toUrl('more.png')">
              <a-menu slot="overlay" @click="selectActionMenu">
                <a-menu-item key="download">模板</a-menu-item>
                <a-menu-item key="import">导入</a-menu-item>
                <a-menu-item key="export">导出</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </template>
      <div class="body-flex">
        <div class="body-left-table">
          <a-table
            style="padding-right: 10px;min-width: 970px;"
            :data-source="list"
            :expandIcon="this.$customExpandIcon"
            rowKey="oid"
            :pagination="pagination"
            @change="paginationChange"
            :customRow="customRow"
            :defaultExpandAllRows="true"
            v-if="renderTable"
          >
            <a-table-column title="ID"  data-index="seqid" width="140px"></a-table-column>
            <a-table-column title="名称" >
              <template v-slot:default="_, row">
                <div class="line-center">
                  <img style="height: 16px; margin-right: 5px;"  :src="$toUrl('issue.png')" alt="">
                  <div style="line-height: 16px">{{row.product_name}}</div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="编号" data-index="product_code">
              <template #default="_, row">
                <span>{{row.product_code}}</span>
                <span v-if="row.product_code && row.company_no">/</span>
                <span>{{row.company_no}}</span>
              </template>
            </a-table-column>
            <a-table-column title="进度" width="100px">
              <template v-slot:default="_, row">
                {{row.complete_percent}}%
              </template>
            </a-table-column>
            <a-table-column title="负责人" data-index="delivery_name" width="120px">
              <template v-slot:default="_, row">
                <div class="edp-owners">
                  <div>
                    <a-avatar :size="16" :src="row.pic" />
                    <span>{{ row.delivery_name }}</span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="BOM数" data-index="require_count" width="120px">
              <template #default="_, row">
                <span v-if="row.level !== 1">{{row.require_count}}</span>
              </template>
            </a-table-column>
            <a-table-column title="计划数量" data-index="schedule_count" width="100px">
              <template #default="_, row">
                <edp-input
                  @click.native.stop
                  v-if="row.level === 1"
                  style="width: 100%;"
                  v-model="row.schedule_count"
                  :db="dbs.prjProduct"
                  field-name="schedule_count"
                  :prj-oid="$route.query.prj_oid"
                  :oid="row.oid"
                  @change="changeScheduleCount(row)"
                ></edp-input>
                <span v-else>{{row.schedule_count || 0}}</span>
              </template>
            </a-table-column>
            <a-table-column title="实际数量" data-index="actual_count" width="130px">
              <template #default="_, row">
                <div class="icon-group" @click.stop>
                  <edp-input
                    style="width: 80px;margin-right: 2px;"
                    v-model="row.actual_count"
                    :db="dbs.prjProduct"
                    field-name="actual_count"
                    :prj-oid="$route.query.prj_oid"
                    :oid="row.oid"
                    @change="() => {
                      getList()
                      getProBom()
                    }"
                  ></edp-input>
                  <span class="label">{{row.actual_count}}</span>
                  <img class="icon" v-if="Number(row.schedule_count || 0) <= Number(row.actual_count || 0)" :src="$toUrl('finish.png')" alt="">
                </div>
<!--                <span v-else>{{row.actual_count || 0}}</span>-->
              </template>
            </a-table-column>
          </a-table>
        </div>
        <product-detail @delete="deletePro" defaultActive="2" @reload="getList" prj-pro :source-oid="currentOid" :prooid="currentProOid">
          <template #bom>
            <div class="tabs-content" style="padding-left:10px">
              <div class="detail bom-table" v-if="renderBomTable">
                <a-table :defaultExpandAllRows="true" :data-source="BOMList" :pagination="false" rowKey="oid">
                  <a-table-column title="名称" data-index="node_name" />
                  <a-table-column title="规格型号" data-index="tech_info"/>
                  <a-table-column title="BOM数" data-index="require_count" width="95">
                    <template #default="_, row">
                      <span v-if="row.level !== 1">{{row.require_count}}</span>
                    </template>
                  </a-table-column>
                  <a-table-column title="计划数量" data-index="schedule_count" width="90px">
                    <template #default="_, row">
                      <edp-input
                        v-if="currentLevel === 1"
                        style="width: 100%;"
                        v-model="row.schedule_count"
                        :db="dbs.prjProduct"
                        field-name="schedule_count"
                        :prj-oid="$route.query.prj_oid"
                        :oid="row.oid"
                        @change="getList"
                      ></edp-input>
                      <span v-else>{{row.schedule_count || 0}}</span>
                    </template>
                  </a-table-column>
                  <a-table-column title="实际数量" data-index="actual_count" width="109px">
                    <template #default="_, row">
                      <div class="icon-group" @click.stop>
                        <edp-input
                          style="width: 70px;margin-right: 2px;"
                          v-model="row.actual_count"
                          :db="dbs.prjProduct"
                          field-name="actual_count"
                          :prj-oid="$route.query.prj_oid"
                          :oid="row.oid"
                          @change="getList"
                        ></edp-input>
                        <span class="label">{{row.actual_count}}</span>
                        <img class="icon" v-if="Number(row.schedule_count || 0) <= Number(row.actual_count || 0)" :src="$toUrl('finish.png')" alt="">
                        <!--                          <span v-else>{{row.actual_count || 0}}</span>-->
                      </div>
                    </template>
                  </a-table-column>
                </a-table>
              </div>
            </div>
          </template>
          <template #bizlog>
            <bizlog ref="bizlog" :source-oid="currentOid"></bizlog>
          </template>
        </product-detail>
      </div>
      <create-project-product @reload="getList" :prjoid="$route.query.prj_oid" ref="modal"></create-project-product>
    </card>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin.js";
import {fetch} from "@/utils/request.js";
import {getTemplate} from "@/utils/helpFunc";
import CreateProjectProduct from "@/components/createProjectProduct.vue";
import catMixin from "@/utils/mixins/catMixin.js";
import EdpInput from "@/components/e-form/edp-input.vue";
import {FieldUpdateDb} from "@/utils/FieldUpdate.js";
import ProductDetail from "@/components/productDetail.vue";
import Bizlog from "@/components/bizlog.vue";

export default {
  components: {Bizlog, ProductDetail, EdpInput, CreateProjectProduct},
  mixins: [listMixin, catMixin],
  data() {
    return {
      dbs: {
        proCore: new FieldUpdateDb("pro", "pro_core"),
        prjProduct: new FieldUpdateDb("prj", "prj_product")
      },
      renderTable: false,
      cat: {
        delivery_status: [],
        product_type: [],
        product_category: [],
      },
      listMixinOptions: {
        mockUrl: "http://127.0.0.1:4523/m1/2234408-0-default/prj/product/retrieve/byprj",
        url: "/prj/product/retrieve/bysupplier"
      },
      page: {
        page_size: 15
      },
      listQuery: {
        prjoid: this.$route.query.prj_oid,
        keywords: "",
        delivery_status: "",
        order_by: ""
      },
      selectedRowKeys: [],
      BOMList: [],
      currentLevel: 1,
      currentOid: '',
      renderBomTable: false,
      companyOptions: [],
      currentSeqid: '',
      currentProOid: "",
      detailActiveTab: "2"
    }
  },
  created() {
    this.getCompanyList()
  },
  methods: {
    deletePro() {
      fetch('post','/prj/product/delete',{
        oid: this.currentOid
      }).then((res)=>{
        if (res.status === 0) {
          this.$message.success('删除成功！')
          this.currentOid = ""
          this.currentProOid = ""
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getCompanyList() {
      fetch('post','/company/retrieve', { keywords: '', page_size: 999}).then((res)=>{
        if (res.status === 0) {
          this.companyOptions = res.data.datas.map(item => {
            return {
              key: item.oid,
              label: item.company_name,
              value: item.oid
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    changeScheduleCount(row) {
      fetch("post", "/prj/product/bom/update", {
        oid: row.oid
      }).then(res => {
        if (res.status === 0) {
          this.getList()
        }
      })
    },
    importData(e) {
      let file = e.target.files[0]
      let formData = new FormData()
      formData.set("file", file)
      formData.set("prjoid", this.$route.query.prj_oid)
      fetch("post", "/prj/product/import", formData).then(res => {
        if (res.status === 0) {
          this.$message.success("导入成功")
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleBom(list, level = 1) {
      return list.map(item => {
        return {
          ...item,
          product_name: item.node_name,
          actual_count: item.actual_count || 0,
          children: item.children?.length ? this.handleBom(item.children, level + 1) : undefined,
          level,
        }
      })
    },
    getProBom() {
      this.renderBomTable = false
      fetch("post", "/prj/product/bom", {
        prooid: this.currentProOid,
        prjoid: this.$route.query.prj_oid,
        seqid: this.currentSeqid
      }).then(res => {
        if (res.status === 0 || window.mock) {
          this.BOMList = this.handleBom(res.data ?? [])
        }
        return res
      }).finally(() => {
        console.log("finally")
        this.renderBomTable = true
      })
    },
    beforeGetList() {
      this.renderTable = true
      if (this.list[0]) {
        console.log(this.currentOid);
        if (!this.currentOid) {
          this.currentOid = this.list[0].oid
          this.currentSeqid = this.list[0].seqid
          this.currentLevel = this.list[0].level ?? 2
          this.currentProOid = this.list[0].prooid
          this.detailActiveTab = "2"
          this.getProBom()
        }
      } else {
        this.hasDetail = false
      }
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.currentOid = record.oid
            this.currentSeqid = record.seqid
            this.currentLevel = record.level ?? 2
            this.currentProOid = record.prooid
            this.detailActiveTab = "2"
            this.getProBom()
          }
        }
      }
    },
    openModal() {
      this.$refs.modal.open()
    },
    selectActionMenu({key}) {
      if (key ==="import") {
        this.$refs.file.click()
      } else if (key === "export") {
        // console.log(this.$toUrl(`/pro/export?oids=${this.list.map(item => item.prooid).join(',')}`, true, "api"));
        if (this.list.length > 0) {
          window.open(this.$toUrl(`/pro/export?oids=${this.list.map(item => item.prooid).join(',')}`, true, "api"))
        }else {
          this.$message.error('暂无产品')
        }
      } else if (key === "download") {
        getTemplate('/attachment/model', '产品导入模版.xlsx')
      }
    },
    changeSelected(list) {
      console.log(list)
      this.selectedRowKeys = list
    },
    delPro(oid) {
      const that = this
      this.$confirm({
        title: `确定删除?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          fetch('post','/prj/product/delete',{
            oid
          }).then((res)=>{
            if (res.status === 0) {
              that.$message.success('删除成功！')
              that.currentOid = ''
              that.getList()
            }else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {}
      });
    }
  }
}
</script>


<style lang="less" scoped>
.icon-group {
  height: 32px;
  display: flex;
  align-items: center;
  &:hover, &:has(.ant-input:focus) {
    .edp-input {
      display: inline-block;
    }
    .label {
      display: none;
    }
  }
  .edp-input {
    display: none;
  }
  .label {
    margin-right: 5px;
  }
  .icon {
    width: 13px;
    height: 13px;
    margin-top: 1px;
  }
}
.bom-table {
  overflow-y: auto;
  overflow-x: auto;
  .ant-table-wrapper {
    /deep/ .ant-table-thead {
      th {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        white-space: nowrap;
      }
    }
    /deep/ .ant-table-tbody {
      td {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
      }
    }
  }
}
.app-main-content {
  /deep/ .page-card-body {
    padding: 0 20px;
    display:flex;
    flex-direction: column;
    // 表格上方的操作栏
    >.body-action {
      display: flex;
      border-bottom: 1px solid #eee;
      .right {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-bottom: 10px;
        .item {
          margin-left: 20px;
          .label {
            margin-right: 10px;
          }
        }
      }
    }
    // 表格和右侧信息的父元素 选择器
    >.body-flex {
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;
      width: 100%;
      // 左侧表格
      >.body-left-table {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        background-color: #fff;
        overflow-x: auto;
        margin-right: 10px;
      }
    }
  }
}
</style>
