<template>
  <a-modal title="创建产品" v-model="visible" width="680px">
    <a-form-model layout="vertical" ref="form" :model="form" class="edp-form">
      <div class="line-2">
        <a-form-model-item label="产品名称" prop="product_name">
          <auto-input
            free-input
            class="value"
            :url="isSupplier ? 'pro/retrieve/bysupplier' : '/pro/retrieve'"
            value-key="oid"
            label-key="product_name"
            v-model="form.prooid"
            :label.sync="form.product_name"
            @change="changeProduct"
            @blur="blurProduct"
          ></auto-input>
        </a-form-model-item>
        <a-form-model-item label="产品编号" prop="product_code">
          <a-input :disabled="!newPro" class="value" v-model="form.product_code"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划数量" prop="product_code">
          <a-input-number style="width: 100%" class="value" v-model="form.schedule_count" :min="1" :precision="2"/>
        </a-form-model-item>
        <a-form-model-item label="品牌" prop="band">
          <a-input :disabled="!newPro" class="value" v-model="form.band"></a-input>
        </a-form-model-item>
        <a-form-model-item label="供应商" prop="company_oid">
          <a-select  :disabled="!newPro" v-model="form.company_oid" @change="companyChange" showSearch optionFilterProp="children" style="width: 100%">
            <a-select-option v-for="item in companyList" :key="item.oid" :value="item.oid" :options="item">
              {{item.company_name}}
            </a-select-option>
          </a-select>
          <!--            <auto-input-->
          <!--                url="/company/retrieve"-->
          <!--                style="width: 100%"-->
          <!--                value-key="oid"-->
          <!--                label-key="company_name"-->
          <!--                v-model="form.company_oid"-->
          <!--                :label.sync="form.company_name"-->
          <!--            />-->
        </a-form-model-item>
        <a-form-model-item label="供应商编号" prop="company_no">
          <auto-input
              style="width: 100%"
              :disabled="!newPro"
              url="/company/retrieve"
              value-key="company_no"
              label-key="company_no"
              v-model="form.company_no"
              :label.sync="form.company_no"
          />
        </a-form-model-item>
        <a-form-model-item label="产品类别" prop="product_category">
          <a-tree-select
              v-model="form.product_category"
              :disabled="!newPro"
              style="width: 100%"
              showSearch
              treeNodeFilterProp="title"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder=""
              tree-default-expand-all
              :tree-data="productCategoryList"
              :replaceFields="{children:'children', title:'cat_name', key:'cat_code', value: 'cat_code' }"
              :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body;}"
          >
              <span slot="title" slot-scope="{ key, value }" style="color: #08c">
                {{ value }}
              </span>
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="产品类型" prop="product_type">
          <a-select :disabled="!newPro" v-model="form.product_type" showSearch optionFilterProp="children" style="width: 100%">
            <a-select-option v-for="item in cat.product_type" :key="item.oid" :value="item.cat_code">
              {{item.cat_name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="产品版本" prop="product_series">
          <a-input :disabled="!newPro" class="value" v-model="form.product_series"></a-input>
        </a-form-model-item>
        <a-form-model-item label="原厂家" prop="product_company">
          <a-select :disabled="!newPro" v-model="form.product_company" showSearch optionFilterProp="children" style="width: 100%">
            <a-select-option v-for="item in companyList" :key="item.oid" :value="item.oid">
              {{item.company_name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="单位" prop="unit">
          <a-input :disabled="!newPro" class="value" v-model="form.unit"></a-input>
        </a-form-model-item>
      </div>
      <a-form-model-item label="规格型号" prop="tech_info">
        <a-textarea :disabled="!newPro" :rows="2" v-model="form.tech_info"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="product_intro">
        <a-textarea :disabled="!newPro" :rows="4" v-model="form.product_intro"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="产品附件">
        <attachment-upload :source-oid="form.oid" @setOid="oid => form.oid = oid" source-type="2"></attachment-upload>
      </a-form-model-item>
    </a-form-model>
    <template #footer>
      <div class="modal-footer" style="display: flex; align-items: center">
        <a-checkbox v-model="next" style="margin-right: auto;">
          继续创建下一个
        </a-checkbox>
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import AutoInput from "@/components/autoInput.vue";
import {fetch} from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin";
import AttachmentUpload from "@/components/attachmentUpload.vue";

export default {
  name: "createProjectProduct",
  emits: ['reload'],
  mixins: [catMixin],
  components: {AttachmentUpload, AutoInput},
  props: {
    prjoid: {
      type: String
    }
  },
  data() {
    return {
      visible: false,
      tempProName: "",
      newPro: true,
      next: false,
      form: {
        oid: "",
        schedule_count: "",
        prooid: "",
        product_name: "",
        product_code: "",
        tech_info: "",
        product_company: "",
        band: "",
        product_category: "",
        company_oid: "",
        company_no: "",
        product_type: "",
        product_series: "",
        product_intro: ""
      },
      cat: {
        product_type: []
      },
      backups: {},
      companyList: [],
      productCategoryList: []
    }
  },
  computed: {
    isSupplier() {
      let roles = JSON.parse(localStorage.getItem('company_info')).Roles
      return !!roles.find(item => item.role_code === 'supplier')
    }
  },
  created() {
    this.backups = JSON.parse(JSON.stringify(this.form))
    this.getCompanyList()
    this.getProductCategoryList()
  },
  methods:{
    blurProduct() {
      if (!this.form.prooid && this.form.product_name) {
        this.form.tech_info = ""
        this.form.product_code = ""
        this.newPro = true
      }
    },
    changeProduct(_, row) {
      this.form.tech_info = row.tech_info
      this.form.product_code = row.product_code
      let obj = JSON.parse(JSON.stringify(row))
      delete obj.oid
      this.form = {
        ...this.form,
        ...obj
      }
      this.newPro = false
    },
    open() {
      this.visible = true
      this.newPro = true
      this.form = JSON.parse(JSON.stringify(this.backups))
    },
    submit() {
      let company_info = JSON.parse(localStorage.getItem("company_info"))
      let params = {
        delivery_useroid: company_info.account_oid,
        delivery_comoid: company_info.company_oid,
        prjoid: this.prjoid,
        is_main: 1,
        ...this.form,
        prooid: this.newPro ? this.form.oid : this.form.prooid
      }

      if (!this.form.schedule_count) {
        this.$message.error("请填写计划数量")
        return
      }

      if (this.newPro) {
        // delete params.prooid
        if (!this.form.product_name) {
          this.$message.error("请填写产品名称")
          return
        }
        if (!this.form.product_code) {
          this.$message.error("请填写产品编号")
          return
        }
        if (!this.form.tech_info) {
          this.$message.error("请填写规格型号")
          return
        }
      } else {
        delete params.product_name
        delete params.product_code
      }

      fetch("post", "/prj/product/add", params).then((res) => {
        if (res.status === 0) {
          this.$message.success("新增成功")
          this.$emit("reload")
          if (this.next) {
            this.open()
          } else {
            this.visible = false
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getCompanyList() {
      fetch('post','/company/retrieve', { keywords: '', page_size: 999}).then((res)=>{
        if (res.status === 0) {
          this.companyList = res.data.datas
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getProductCategoryList() {
      fetch('post','/cpy/cat/getall', { keywords: '', cat_type: 'product_category', page_size: 999}).then((res)=>{
        if (res.status === 0) {
          this.productCategoryList = res.data.categories
        } else {
          this.$message.error(res.message)
        }
      })
    },
    companyChange(value, option) {
      let item = option.data.attrs.options
      this.form.company_no = item.company_no
    }
  }
}
</script>

<style lang="less" scoped>
.form-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.form-item {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .label {
    width: 80px;
    position: relative;
    flex-shrink: 0;
  }
  >.value {
    flex: 1;
  }
}
</style>